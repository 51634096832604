<template>
  <Alerta />
  <div class="views">
    <component :is="layout" />
  </div>
</template>

<script setup>
import { computed, inject, onMounted, onBeforeMount, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStoreAlerta } from '@stores'
import { helperBloqueioBody, helperRedirectBrowser, helperToken } from '@helpers'
import Alerta from '@components/global/alerta/Alerta.vue'

const { currentRoute } = useRouter()
const route = useRoute()

const emitter = inject('emitter')
const storeAlerta = useStoreAlerta()

const layout = computed(() => {
  if (!currentRoute?.value?.name) return
  return currentRoute?.value.meta.layout || 'default-layout'
})

function mostrarAlerta(payload) {
  const dados = {
    visivel: true,
    mensagem: payload.mensagem,
    classe: payload.tipo
  }
  storeAlerta.mostrarAlerta(dados)
}

function detectarModalAberto() {
  setInterval(() => helperBloqueioBody.bloquearBodyQuandoAbrirModal(), 500)
}

function redirecionarBrowser() {
  helperRedirectBrowser.redirectAndroidInAppBrowsers()
}

function detectarRotaProibida(route) {
  const rotasSemPerfil = ['/', '/carregando', '/erro']
  return rotasSemPerfil.includes(route.path)
}

function verificarToken() {
  const tokenOk = helperToken.detectarTokenOk()
  if (tokenOk) return

  window.location.assign(`/`)
}

watch(
  () => route.path,
  (newPath) => {
    if (!detectarRotaProibida({ path: newPath })) {
      verificarToken()
    }
  },
  { immediate: true }
)

onMounted(() => {
  detectarModalAberto()
  emitter.on('mostrarAlerta', (payload) => mostrarAlerta(payload))
})

onBeforeMount(() => {
  redirecionarBrowser()
})
</script>
