export default [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/erro'
  },
  {
    path: '/erro',
    name: 'erro',
    component: () => import('../components/app/erro/Erro.vue'),
    meta: {
      logged: false,
      title: 'Essa página não existe na JokenPlay...',
      layout: 'default-layout'
    }
  },
  {
    path: '/inicio',
    name: 'inicio',
    component: () => import('../components/app/inicio/Inicio.vue'),
    meta: {
      logged: true,
      title: 'Usuários JokenPlay',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: () => import('../components/app/perfil/Perfil.vue'),
    meta: {
      logged: true,
      title: 'Perfil na JokenPlay',
      layout: 'navbar-layout'
    }
  }
]
