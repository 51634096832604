export default [
  {
    path: '/entrar',
    redirect: '/'
  },
  {
    path: '/',
    name: 'entrar',
    component: () => import('../components/autenticacao/entrar/Entrar.vue'),
    meta: {
      logged: false,
      title: 'Admin JokenPlay',
      layout: 'default-layout'
    }
  },
  {
    path: '/carregando',
    name: 'carregando',
    component: () => import('../components/autenticacao/carregando/Carregando.vue'),
    meta: {
      logged: true,
      title: 'Carregando Admin',
      layout: 'default-layout'
    }
  }
]
